import useScrollPosition from "@react-hook/window-scroll";
import { Row, Divider, Avatar } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect } from "react";
import intl from "react-intl-universal";
import { shallowEqual, useDispatch } from "react-redux";
import { ButtonLink, Flex, Link, RawButton, Spacer, Text } from "src/components";
import { requestLogin } from "src/redux/modules/Login";
import { cleanSignup } from "src/redux/modules/Signup/actions";
import { cleanUserInfo } from "src/redux/modules/UserInfo/actions";
import useSelector from "src/redux/store/useSelector";
import { removeToken } from "src/services/client";
import { CloseIcon, HamburgerYellowIcon, LogoIcon, FullManorleadLogo } from "src/static";
import useGoogleAnalytics, { Category } from "src/utils/GoogleAnalytics";
import { authTitle, AUTH_TITLE, DEFAULT_PROFILE_AVATAR } from "src/utils";
import styled from "styled-components";
import { LocaleSelector } from "../Shared/localeSelector";
import { NavbarProps } from "./index";
import LoginRegiButton from "./LoginRegiButton/LoginRegiButton";
import { HEADER_CONTENT, HEADER_CONTENT_LOGGED_IN } from "./DesktopMenu";
import { isRoute } from "./NavbarItem";

const DELAY_S = 0.1;

type MobileMenuProps = NavbarProps & {
    isOpen: boolean;
    children: React.ReactNode;
    openMenu: () => void;
    closeMenu: () => void;
    logged: boolean;
    dispatchAuth: (title: string, popup: boolean) => void;
};
/**
 * Mobile menu for links in the navbar
 */
const MobileMenu: React.FC<MobileMenuProps> = ({
    $isImageAsBackground,
    isOpen,
    openMenu,
    closeMenu,
    children,
    logged,
    dispatchAuth,
}) => {
    const scrollY = useScrollPosition(60);
    const dispatch = useDispatch();
    const [avatar, setAvatar] = React.useState(DEFAULT_PROFILE_AVATAR);
    const loginResponse = useSelector((state) => state.login?.data ?? [], shallowEqual);
    const bp = useBreakpoint();
    const userInfoResponse = useSelector((state) => state.userInfo?.data ?? false, shallowEqual);

    const { sendEvent } = useGoogleAnalytics();

    /* TODO: duplicate in DesktopMenu */
    const handleLogout = async () => {
        closeMenu();
        sendEvent(
            Category.BUTTON,
            `User clicked on log out button: ${loginResponse.userProfile?.profileId}`,
            "MobileMenu",
        );

        await dispatch(
            requestLogin({
                endpoint: "signout",
                body: "body",
                method: "POST",
            }),
        );

        localStorage.removeItem("refresh_token");
        removeToken();
        setAvatar(DEFAULT_PROFILE_AVATAR);
        dispatch(cleanUserInfo());
        dispatch(cleanSignup());
        dispatchAuth(authTitle(AUTH_TITLE.LOGIN), false);
    };

    useEffect(() => {
        if (loginResponse.cognitoJWT) {
            if (userInfoResponse.userProfile) {
                setAvatar((avatar) =>
                    userInfoResponse?.userProfile?.profilePictureUrl
                        ? userInfoResponse?.userProfile?.profilePictureUrl
                        : avatar,
                );
            } else {
                setAvatar((avatar) =>
                    userInfoResponse?.profilePictureUrl
                        ? userInfoResponse?.profilePictureUrl
                        : avatar,
                );
            }
        }
    }, [userInfoResponse, loginResponse.cognitoJWT]);

    const handleAuth = (title: string) => {
        dispatchAuth(title, true);
        closeMenu();
    };

    const handleAuthClick = (authCase: AUTH_TITLE) => {
        if (authCase === AUTH_TITLE.LOGIN) {
            sendEvent(Category.BUTTON, "clicks on log in on nav bar by user", "MobileMenu");
        } else if (authCase === AUTH_TITLE.SIGNUP) {
            sendEvent(Category.BUTTON, "clicks on sign up on nav bar by user", "MobileMenu");
        } else if (authCase === AUTH_TITLE.LOGIN_OR_REGISTER) {
            sendEvent(Category.BUTTON, "clicks on login/register on nav bar by user", "MobileMenu");
        }
        handleAuth(authTitle(authCase));
    };

    const userBlock = logged && (
        <>
            <AvatarBox>
                <Avatar src={avatar} size="large" />
            </AvatarBox>
            <Spacer height={16} />
            <AnimatedLink href="/profile" delay_n={1}>
                <LinkText onClick={closeMenu}>{intl.get("navbar.accountSetting")}</LinkText>
            </AnimatedLink>
            <StyledDivider />
        </>
    );

    const headerContent = logged ? HEADER_CONTENT_LOGGED_IN : HEADER_CONTENT;

    const notLastItem = (index: number, array: any[]) => {
        return index !== array.length - 1;
    };

    return (
        <>
            <ContentWrapper
                boxShadow={
                    scrollY <= 60 && $isImageAsBackground
                        ? "none"
                        : "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),0px 0px 1px rgba(0, 0, 0, 0.04)"
                }
                style={{
                    background: scrollY <= 60 && $isImageAsBackground ? "transparent" : "white",
                }}
            >
                <div style={{ position: "absolute", top: 18, left: "2%" }}>
                    <HamburgerMenu onClick={openMenu}>
                        <StyledImg src={HamburgerYellowIcon} alt="hamburger-menu-icon" />
                    </HamburgerMenu>
                </div>
                <Background className={isOpen ? "expanded" : undefined} />
                <LogoCenter>
                    <StyledLink href="/">
                        <img src={FullManorleadLogo} alt="manorlead-logo" />
                    </StyledLink>
                </LogoCenter>
                {!logged && !bp.xs && (
                    <div style={{ position: "absolute", right: "2%", top: "12px" }}>
                        <LoginRegiButton handleAuthClick={handleAuthClick} />
                    </div>
                )}

                <MenuOpenContentContainer show={isOpen} column>
                    <Flex justify="space-between">
                        <StyledLink href="/" onClick={closeMenu}>
                            <img src={LogoIcon} alt="manorlead-logo" />
                        </StyledLink>
                        <RawButton onClick={closeMenu}>
                            <img src={CloseIcon} alt="close-icon" />
                        </RawButton>
                    </Flex>
                    <Spacer height={37} />
                    {userBlock}
                    <Flex column>
                        {headerContent.map(({ title, route, icon }, i) => {
                            if (typeof title === "string" && isRoute(route)) {
                                return (
                                    <div key={title}>
                                        <AnimatedLink
                                            href={`${route.pathname}?${route.search}`}
                                            delay_n={i}
                                        >
                                            <LinkText onClick={closeMenu}>
                                                {intl.get(title)}
                                                {icon}
                                            </LinkText>
                                        </AnimatedLink>
                                        {notLastItem(i, headerContent) && <Spacer height={16} />}
                                    </div>
                                );
                            } else if (typeof title !== "string" && !isRoute(route)) {
                                return route.map((url, urlIndex) => (
                                    <div key={title[urlIndex + 1]}>
                                        <AnimatedLink
                                            href={`${url.pathname}?${url.search}`}
                                            delay_n={urlIndex}
                                        >
                                            <LinkText onClick={closeMenu}>
                                                {intl.get(title[urlIndex + 1])}
                                            </LinkText>
                                        </AnimatedLink>
                                        {(notLastItem(urlIndex, route) ||
                                            notLastItem(i, headerContent)) && (
                                            <Spacer height={16} />
                                        )}
                                    </div>
                                ));
                            } else {
                                return <></>;
                            }
                        })}
                    </Flex>
                    <StyledDivider />
                    <LocaleSelector ismobile={true} />
                    <AuthLoginRegisterContainer>
                        {logged ? (
                            <StyledButtonLink
                                text={intl.get("navbar.logout")}
                                onClick={() => handleLogout()}
                            />
                        ) : (
                            <>
                                <StyledButtonLink
                                    text={intl.get("navbar.register")}
                                    onClick={() => handleAuthClick(AUTH_TITLE.SIGNUP)}
                                />
                                <Spacer height={8} />
                                <StyledButtonLinkGrey
                                    text={intl.get("navbar.login")}
                                    onClick={() => handleAuthClick(AUTH_TITLE.LOGIN)}
                                />
                            </>
                        )}
                    </AuthLoginRegisterContainer>
                    <Spacer height={48} />
                </MenuOpenContentContainer>
            </ContentWrapper>
            {!$isImageAsBackground && <Spacer height={64} />}
            <div>{children}</div>
        </>
    );
};

export default MobileMenu;

const ContentWrapper = styled(Row)<{
    boxShadow?: string;
}>`
    width: 100%;
    position: fixed;
    z-index: 1000;
    height: 64px;
    touch-action: none;
    box-shadow: ${({ boxShadow }) => boxShadow};
`;

const MenuOpenContentContainer = styled(Flex)<{ show?: boolean }>`
    top: 0;
    left: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    padding: 40px 10% 0;
    display: ${({ show }) => (show ? "block" : "none")};
    opacity: ${({ show }) => (show ? "1" : "0")};
    z-index: 1000;
    overflow-y: scroll;
`;

const Background = styled.div`
    border-radius: 50%;
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 15px;
    width: 1vw;
    height: 1vh;
    background: ${({ theme }) => theme.color.white};
    opacity: 0;
    transition: transform 300ms cubic-bezier(0, 0.995, 0.99, 1),
        opacity 300ms cubic-bezier(0, 0.995, 0.99, 1);
    &.expanded {
        opacity: 1;
        transform: scale(600);
    }
`;

const AnimatedLink = styled(Link)<{ delay_n: number }>`
    transition: all 1250ms cubic-bezier(0, 0.995, 0.99, 1);
    transition-delay: ${({ delay_n }) => delay_n * DELAY_S}s;
`;

const StyledImg = styled.img`
    width: 32px;
    height: 32px;
`;

const HamburgerMenu = styled(RawButton)`
  position: absolute;
  top: 5px
  left: 10px
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
`;

const LinkText = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    ${({ theme }) => theme.styleGroup.body}
`;

const StyledButtonLink = styled(ButtonLink)`
    width: 100%;
    padding: 8px 16px;
    border-radius: 8px;
`;

const StyledButtonLinkGrey = styled(StyledButtonLink)`
    background-color: ${({ theme }) => theme.color.greySecondaryL};
`;

const AvatarBox = styled.a`
    cursor: pointer;
`;

const LogoCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const AuthLoginRegisterContainer = styled.div`
    margin-top: 32px;
`;

const StyledDivider = styled(Divider)`
    margin: 16px 0;
    border-top-color: #c4c4c4;
`;
