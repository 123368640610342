export { default as FontMerriweatherRegularWOFF } from "./fonts/merriweather-v21-latin-regular.woff";
export { default as FontMerriweatherRegularWOFF2 } from "./fonts/merriweather-v21-latin-regular.woff2";
export { default as FontMerriweatherBoldWOFF } from "./fonts/merriweather-v21-latin-700.woff";
export { default as FontMerriweatherBoldWOFF2 } from "./fonts/merriweather-v21-latin-700.woff2";

export { default as FontRubikLightWOFF } from "./fonts/rubik-v9-latin-300.woff";
export { default as FontRubikLightWOFF2 } from "./fonts/rubik-v9-latin-300.woff2";
export { default as FontRubikRegularWOFF } from "./fonts/rubik-v9-latin-regular.woff";
export { default as FontRubikRegularWOFF2 } from "./fonts/rubik-v9-latin-regular.woff2";
export { default as FontRubikMediumWOFF } from "./fonts/rubik-v9-latin-500.woff";
export { default as FontRubikMediumWOFF2 } from "./fonts/rubik-v9-latin-500.woff2";

/**
 * Export all images as modules so that webpack can deal with them appropriately
 * and in one file so that we have a centralized reference to all the images.
 * This means we only have to change one path and not all files that are using
 * that image if we change the src of the image.
 */
/* Decorative Images */
export const BusinessAgentsImg =
    "https://frontend-static-images.s3.amazonaws.com/still/business-agents.svg";
export const LaptopGearImg =
    "https://frontend-static-images.s3.amazonaws.com/still/laptop-gear.svg";
export const ServiceBellImg =
    "https://frontend-static-images.s3.amazonaws.com/still/service-bell.svg";
export const LuxuryHome1Img =
    "https://frontend-static-images.s3.amazonaws.com/still/luxury-home-1.jpg";
export const LuxuryHome2Img =
    "https://frontend-static-images.s3.amazonaws.com/still/luxury-home-2.png";
export const MortgageImg = "https://frontend-static-images.s3.amazonaws.com/still/mortgage.svg";
export const HomeInspectionImg =
    "https://frontend-static-images.s3.amazonaws.com/still/inspection.svg";
export const LockImg = "https://frontend-static-images.s3.amazonaws.com/still/lock.svg";
export const LightbulbImg = "https://frontend-static-images.s3.amazonaws.com/still/lightbulb.svg";
export const ChartImg = "https://frontend-static-images.s3.amazonaws.com/still/chart.svg";
export const TrophyImg = "https://frontend-static-images.s3.amazonaws.com/still/trophy.svg";
export const MortgageServiceImg =
    "https://frontend-static-images.s3.amazonaws.com/still/mortgage-services.svg";
export const NoImage = "https://frontend-static-images.s3.amazonaws.com/still/noImageExists.png";
export const NoDetailedListingImage =
    "https://frontend-static-images.s3.amazonaws.com/placeholder/placeholder_no_listing.svg";
export const NoPromotionImage =
    "https://frontend-static-images.s3.amazonaws.com/placeholder/placeholder_no_promo.svg";
export const TechnologyImage =
    "https://frontend-static-images.s3.amazonaws.com/background/technology.png";
export const VideoCover =
    "https://frontend-static-images.s3.amazonaws.com/background/videoCover.png";
export const ContactUs = "https://frontend-static-images.s3.amazonaws.com/background/contactUs.png";
export const ServiceHuman =
    "https://frontend-static-images.s3.amazonaws.com/still/service-human.png";
export const ExclusivePic1 =
    "https://frontend-static-images.s3.amazonaws.com/Exclusive/image40.png";
export const ExclusivePic2 =
    "https://frontend-static-images.s3.amazonaws.com/Exclusive/Group138.png";
export const PageNotFoundImg =
    "https://frontend-static-images.s3.amazonaws.com/errors/page-not-found-image.png";

export { default as LocationMarkerImg } from "./images/LocationMarker.png";

/* Company Logos */
export const AmazonLogo = "https://frontend-static-images.s3.amazonaws.com/logos/amazon.svg";
export const BMOLogo = "https://frontend-static-images.s3.amazonaws.com/logos/bmo.svg";
export const FacebookLogo = "https://frontend-static-images.s3.amazonaws.com/logos/facebook.svg";
export const GoogleLogo = "https://frontend-static-images.s3.amazonaws.com/logos/google.svg";
export const OracleLogo = "https://frontend-static-images.s3.amazonaws.com/logos/oracle.svg";
export const RBCLogo = "https://frontend-static-images.s3.amazonaws.com/logos/rbc.svg";
export const SalesforceLogo =
    "https://frontend-static-images.s3.amazonaws.com/logos/salesforce.svg";
export const WishLogo = "https://frontend-static-images.s3.amazonaws.com/logos/wish.svg";
export { default as ManorbrainLogo } from "./images/logo-manorBrain.png";

/* Authentication View Logo */
export const HomeLogo =
    "https://frontend-static-images.s3.amazonaws.com/icons/homeRegisterImage.svg";
export { default as ManorleadLogo } from "./images/ManorleadLogo.png";

export const SellCard1Img = "https://frontend-static-images.s3.amazonaws.com/still/sell-card1.svg";
export const SellCard2Img = "https://frontend-static-images.s3.amazonaws.com/still/sell-card2.svg";

export { default as RBCGreyLogo } from "./images/RBCGreyLogo.png";
export { default as BMOGreyLogo } from "./images/BMOGreyLogo.png";
export { default as NationalBankGreyLogo } from "./images/NationalBankGreyLogo.png";
export { default as BakerGreyLogo } from "./images/BakerGreyLogo.png";

/*  3d models  */
export { default as MacModel } from "./models/mac.glb";

/* sky box */
export { default as top } from "./models/bg/top.jpg";
export { default as right } from "./models/bg/right.jpg";
export { default as left } from "./models/bg/left.jpg";
export { default as bottom } from "./models/bg/bottom.jpg";
export { default as back } from "./models/bg/back.jpg";
export { default as front } from "./models/bg/front.jpg";

/* Social Icons */
export const EmailIcon = "https://frontend-static-images.s3.amazonaws.com/icons/email.svg";
export const FacebookIcon = "https://frontend-static-images.s3.amazonaws.com/icons/facebook.svg";
export const InstagramIcon = "https://frontend-static-images.s3.amazonaws.com/icons/instagram.svg";
export const TwitterIcon = "https://frontend-static-images.s3.amazonaws.com/icons/twitter.svg";
export const LogoIcon = "https://frontend-static-images.s3.amazonaws.com/icons/logo.svg";
export const FullManorleadLogo =
    "https://frontend-static-images.s3.amazonaws.com/logos/manorlead-logo-may-31.svg";
export const HamburgerWhiteIcon =
    "https://frontend-static-images.s3.amazonaws.com/icons/hamburgerWhite.svg";
export const HamburgerYellowIcon =
    "https://frontend-static-images.s3.amazonaws.com/icons/hamburgerYellow.svg";
export const CloseIcon = "https://frontend-static-images.s3.amazonaws.com/icons/close.svg";

export const FacebookSell =
    "https://frontend-static-images.s3.amazonaws.com/icons/facebookSell.svg";
export const InstagramSell =
    "https://frontend-static-images.s3.amazonaws.com/icons/instagramSell.svg";
export const WechatSell = "https://frontend-static-images.s3.amazonaws.com/icons/wechatSell.svg";
export const YoutubeSell = "https://frontend-static-images.s3.amazonaws.com/icons/youtubeSell.svg";
export const YellowEcllipse =
    "https://frontend-static-images.s3.amazonaws.com/icons/yellowEcllipse.svg";
export const GoogleIcon = "https://frontend-static-images.s3.amazonaws.com/icons/google.svg";

/* Background Images */
export const AuthBackgroundImg =
    "https://frontend-static-images.s3.amazonaws.com/background/authentication-bg.png";
// export const HeroImg = "https://frontend-static-images.s3.amazonaws.com/background/hero.png";
export const SkylineImg = "https://frontend-static-images.s3.amazonaws.com/background/skyline.png";
export const AsanohaImg = "https://frontend-static-images.s3.amazonaws.com/background/asanoha.png";
export const AgentsImg = "https://frontend-static-images.s3.amazonaws.com/background/agents.png";
export const PlainGrey =
    "https://frontend-static-images.s3.amazonaws.com/background/plain_grey.png";
export const HERO_Community =
    "https://frontend-static-images.s3.amazonaws.com/background/hero-community.png";
export const HERO_HOME =
    "https://frontend-static-images.s3.amazonaws.com/background/toronto-hero.png";

/* Marker Icons */
export const Detached = "https://frontend-static-images.s3.amazonaws.com/icons/detached.svg";
export const SemiDetached =
    "https://frontend-static-images.s3.amazonaws.com/icons/semiDetached.svg";
export const TownHouse = "https://frontend-static-images.s3.amazonaws.com/icons/townHouse.svg";
export const DetachedGold =
    "https://frontend-static-images.s3.amazonaws.com/icons/detachedGold.svg";
export const SemiDetachedGold =
    "https://frontend-static-images.s3.amazonaws.com/icons/semiDetachedGold.svg";
export const TownHouseGold =
    "https://frontend-static-images.s3.amazonaws.com/icons/townHouseGold.svg";

/* Default picture */
export const Model = "https://frontend-static-images.s3.amazonaws.com/still/model.jpg";

/* QR */
export const WechatQR = "https://frontend-static-images.s3.amazonaws.com/contact/wechatQR.jpg";
export const Wechat = "https://frontend-static-images.s3.amazonaws.com/contact/wechat.png";
export const WhatsupQR = "https://frontend-static-images.s3.amazonaws.com/contact/whatsupQR.jpg";
export const Whatsup = "https://frontend-static-images.s3.amazonaws.com/contact/whatsup.png";
export const TelegramQR = "https://frontend-static-images.s3.amazonaws.com/contact/telegramQR.png";
export const Telegram = "https://frontend-static-images.s3.amazonaws.com/contact/telegram.png";

/* Community Cover */
export const Cachet =
    "https://frontend-static-images.s3.amazonaws.com/community/community-cachet.png";
export const BayviewGlen =
    "https://frontend-static-images.s3.amazonaws.com/community/community-bayview-glen.png";
export const BayviewHill =
    "https://frontend-static-images.s3.amazonaws.com/community/community-bayview-hill.png";
export const Yorkville =
    "https://frontend-static-images.s3.amazonaws.com/community/community-yorkville.png";
export const Bridlepath =
    "https://frontend-static-images.s3.amazonaws.com/community/community-bridlepath.png";
export const Davisville =
    "https://frontend-static-images.s3.amazonaws.com/community/community-davisville.png";
export const RougeWoods =
    "http://frontend-static-images.s3.amazonaws.com/community/community-rouge-woods.png";

/* development */
export const AmenitiesImg =
    "https://frontend-static-images.s3.amazonaws.com/development/amenities.png";

/* School Type Icons */
export const APIcon = "https://frontend-static-images.s3.amazonaws.com/logos/ap.png";
export const IBIcon = "https://frontend-static-images.s3.amazonaws.com/logos/ib.png";

/* coolicons */
export { ReactComponent as DownIcon } from "./coolicons/caret_down.svg";
export { ReactComponent as UpIcon } from "./coolicons/caret_up.svg";
export { ReactComponent as BuildingIcon } from "./coolicons/building.svg";
export { ReactComponent as ClockIcon } from "./coolicons/clock.svg";
export { ReactComponent as TagIcon } from "./coolicons/tag-outline.svg";
export { ReactComponent as LocationIcon } from "./coolicons/location-outline.svg";
export { ReactComponent as WarningIcon } from "./coolicons/warning-outline.svg";
export { ReactComponent as CirclRightIcon } from "./coolicons/circle_chevron_right.svg";
export { ReactComponent as IDCardIcon } from "./coolicons/id_card.svg";
export { ReactComponent as SearchIcon } from "./coolicons/search.svg";
export { ReactComponent as BedroomIcon } from "./coolicons/bedroom-outline.svg";
export { ReactComponent as StoriesIcon } from "./coolicons/stories.svg";
export { ReactComponent as RulerIcon } from "./coolicons/ruler-icon.svg";
export { ReactComponent as SolidTagIcon } from "./coolicons/tag.svg";
export { ReactComponent as CircleCheckIcon } from "./coolicons/circle_check.svg";
export { ReactComponent as TooltipIcon } from "./coolicons/tooltip-icon.svg";
export { ReactComponent as CalendarIcon } from "./coolicons/calendar_check.svg";
export { ReactComponent as HomeIcon } from "./coolicons/home-icon.svg";
export { ReactComponent as BathtubIcon } from "./coolicons/bathtub-outline.svg";
export { ReactComponent as TrashcanIcon } from "./coolicons/trashcan-outline.svg";
export { ReactComponent as CrossIcon } from "./coolicons/cross_icon.svg";
export { ReactComponent as HomePageSearchIcon } from "./coolicons/homePage_search_icon.svg";
export { ReactComponent as QuestionMarkIcon } from "./coolicons/help_questionmark.svg";
export { ReactComponent as ErrorIcon } from "./coolicons/error_outline.svg";
export { ReactComponent as ContactUsIcon } from "./coolicons/contact-icon.svg";
export { ReactComponent as MapIcon } from "./coolicons/map.svg";
export { ReactComponent as BarChartIcon } from "./coolicons/bar_chart_square.svg";
export { ReactComponent as LongUpIcon } from "./coolicons/long_up.svg";
export { ReactComponent as LongDownIcon } from "./coolicons/long_down.svg";
export { ReactComponent as CheckIcon } from "./coolicons/check.svg";
export { ReactComponent as TrendingUpIcon } from "./coolicons/trending_up.svg";
export { ReactComponent as SunIcon } from "./coolicons/sun.svg";
export { ReactComponent as TemperatureIcon } from "./coolicons/temperature.svg";
export { ReactComponent as LockerIcon } from "./coolicons/locker.svg";
export { ReactComponent as MaintenanceIcon } from "./coolicons/maintenance.svg";
export { ReactComponent as TransferIcon } from "./coolicons/transfer.svg";
export { ReactComponent as CalendarDateIcon } from "./coolicons/calendar.svg";
export { ReactComponent as StylishCondoIcon } from "./coolicons/stylish-condo.svg";
export { ReactComponent as CarIcon } from "./coolicons/car.svg";
export { ReactComponent as ErrorFillIcon } from "./coolicons/error.svg";
export { ReactComponent as WarningFilledIcon } from "./coolicons/warning.svg";
export { ReactComponent as ExternalLinkIcon } from "./coolicons/external_link.svg";
export { ReactComponent as LongRightIcon } from "./coolicons/long_right.svg";
export { ReactComponent as SendIconFill } from "./coolicons/send-icon-white-fill.svg";

/* home page images */
export { default as BMO } from "./images/supporters/bmo.png";
export { default as NBC } from "./images/supporters/nbc.png";
export { default as Plaza } from "./images/supporters/plaza.png";
export { default as RBC } from "./images/supporters/rbc.png";
export { default as FakeCustomer } from "./images/fake-customer.png";
export { default as Narcity } from "./images/featuredon/logo-narcity.png";
export { default as Fairchild } from "./images/featuredon/logo-fairchild.png";
export { default as CanadianSME } from "./images/featuredon/logo-canadiansme.png";
export { default as GoogleNews } from "./images/featuredon/logo-google-news.png";
export { default as Uwaterloo } from "./images/featuredon/logo-university-of-waterloo.png";
export { default as YahooFinance } from "./images/featuredon/logo-yahoo-finance.png";
export { default as Betakit } from "./images/featuredon/logo-betakit.png";
export { default as BNNBloomberg } from "./images/featuredon/logo-bnn-bloomberg.png";
export { default as Storeys } from "./images/featuredon/logo-storeys.png";
export { default as TCN } from "./images/featuredon/logo-tcn.png";
export { default as Urbanize } from "./images/featuredon/logo-urbanize.png";

export const Dev1 =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-newDev-tower-1.png";
export const Dev2 =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-newDev-tower-2.png";
export { default as GTAMap } from "./images/gta-map.png";
export const GroupPic =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-client-numbers-section-miao.png";
export { default as HomeBackground1 } from "./images/background1.png";
export const Banner =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-homesearchai-demo-mac.gif";
export const PlazaBanner =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-hero-wellesley-station.png";
export const VideoBanner =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-homesearchai.mp4";
export const HomePageDot =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-dots-accent.png";
export const AppStore = "https://frontend-static-images.s3.amazonaws.com/logos/appStore.png";
export const BlueDots = "https://frontend-static-images.s3.amazonaws.com/still/blueDots.png";
export const GooglePlay = "https://frontend-static-images.s3.amazonaws.com/logos/googlePlay.png";
export const MobileScreens =
    "https://frontend-static-images.s3.amazonaws.com/still/mobileScreens.png";
export { default as TrumpetImage } from "./images/trumpet.svg";

/* SEO Images for all pages */
export enum SEO_IMAGE {
    HOMEPAGE = "https://frontend-static-images.s3.amazonaws.com/seo/img-homepage.png",
    DEV_MAP_PAGE = " https://frontend-static-images.s3.amazonaws.com/seo/img-new-dev-maps.png",
    RES_MAP_PAGE = "https://frontend-static-images.s3.amazonaws.com/seo/img-residential-maps.png",
    SAVED_HOME = " https://frontend-static-images.s3.amazonaws.com/seo/img-saved-homes.png",
    COMMUNITY_GUIDE = "https://frontend-static-images.s3.amazonaws.com/seo/img-guides.png",
}

/* Portfolio Images */
export const CheckmarkImg =
    "https://frontend-static-images.s3.amazonaws.com/portfolio/portfolio-checkmark.png";
export const PortfolioLandingImg =
    "https://frontend-static-images.s3.amazonaws.com/portfolio/portfolio-landing.png";
export const PortfolioFormImg =
    "https://frontend-static-images.s3.amazonaws.com/portfolio/portfolio-400-king.png";
export { default as ResidentialPortfolioLandingImage } from "./images/residentialPortfolioLandingImage.png";
export const WilliamAvatar =
    "https://frontend-static-images.s3.amazonaws.com/avatar/WilliamAva.jpg";
export const GraceAvatar = "https://frontend-static-images.s3.amazonaws.com/avatar/GraceAva.jpg";
export const RichardAvatar =
    "https://frontend-static-images.s3.amazonaws.com/avatar/avatar-richard-xie.jpg";
export const EdwardAvatar =
    "https://frontend-static-images.s3.amazonaws.com/avatar/avatar-edward-sun.jpg";
export const OfficeFrontDesk =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-office-entrance.jpg";
export const ConstructionPlaceholder =
    "https://frontend-static-images.s3.amazonaws.com/placeholder/placeholder_construction.jpg";
export { default as CommunityMapPlaceholderImage } from "./images/CommunityMapPlaceholder.png";
export { default as CommunityNewsPlaceholderImage } from "./images/CommunityNewsPlaceholder.png";
export { default as ManorHomeAboutImage } from "./images/manorHomeAboutImg.png";
export { default as FeedbackInsigntsImage } from "./images/FeedbackInsignts.png";

/* Advertisement */
export const PlazaAd =
    "https://frontend-static-images.s3.amazonaws.com/development/banner-plaza-king-east.png";

export const TheOneAd =
    "https://frontend-static-images.s3.amazonaws.com/development/banner-the-one.png";

export const HarbourAd =
    "https://frontend-static-images.s3.amazonaws.com/development/banner-tridel-6080.png";

export const TridelAd =
    "https://frontend-static-images.s3.amazonaws.com/development/banner-tridel-about.png";

export const YimoAd = "https://frontend-static-images.s3.amazonaws.com/background/bg-yimo.png";

export const RBCBannerAd =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-kelley-yang-short-jun-14-2023.jpg";

export const RBCBannerAdFull =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-kelley-yang-full-new.jpg";

export const CIBCBannerAd =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-danru-lai-short.jpg";

export const CIBCBannerAdFull =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-danru-lai-full.jpg";

export const ZhangBannerAd =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-jim-zhang-short.jpg";

export const ZhangBannerAdFull =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-jim-zhang-full.jpg";

export const NearbyCondosBackground =
    "https://frontend-static-images.s3.amazonaws.com/background/bg-hero-nearby-condos.png";

// listing icons
export { default as aboutAvg } from "./images/icon-about-avg.png";
export { default as belowAvg } from "./images/icon-below-avg.png";
