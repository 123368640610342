import React from "react";
import styled from "styled-components";
import { ListingMenuButtonFull } from "src/components/Shared/ListingMenuButton";
import intl from "react-intl-universal";
import { AUTH_TITLE } from "src/utils";

const LoginRegiButton = (props: { handleAuthClick: (title: AUTH_TITLE) => void }) => {
    const { handleAuthClick } = props;

    return (
        <StyledLoginRegisterButton
            type="primary"
            onClick={() => handleAuthClick(AUTH_TITLE.LOGIN_OR_REGISTER)}
        >
            {intl.get("navbar.login")}/{intl.get("navbar.register")}
        </StyledLoginRegisterButton>
    );
};

const StyledLoginRegisterButton = styled(ListingMenuButtonFull)`
    background: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.blackPrimary};
    width: auto;
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-weight: 500;
    height: 40px;
    ${({ theme }) => theme.styleGroup.body}
`;

export default LoginRegiButton;
