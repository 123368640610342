export const HOME_PAGE_URL = "/";

export const SERVICES_URL = "/services";

export const ABOUT_URL = "/about";

export const PROFILE_URL = "/profile";

export const EXCLUSIVE_URL = "/exclusive";

export const SAVED_URL = "/savedhome";

export const PAGE_NOT_FOUND_URL = "/page-not-found";

export const ERROR_PAGE = "/general-error";

export const REDIRECT_URL = "/redirect";

export const SITEMAP_URL = (name: string) => `/sitemap/${name}`;

export const APP_URL = (appVariable?: string) => `/app${appVariable ? `/${appVariable}` : ""}`;

export const PRIVACY_URL = "/privacy";

export const LIST_OF_LISTINGS_URL = "/listOfListings";

export const LIST_OF_DEVELOPMENTS_URL = "/listOfDevelopments";

export const CHAT_BOT_URL = "/chat";

export const BRAIN_URL = "/brain";

export const PORTFOLIO_URL = {
    landing: "/portfolio",
    personal: "/portfolio-personal",
    purchasedBase: "/portfolio/purchased",
    buildPurchasedUri: (id: string) => `/portfolio/purchased/${id}`,
    buildFormUri: (buildingId: string) => `/portfolio/${buildingId}`,
    buildUnitUri: (buildingId: string) => `/portfolio/${buildingId}/unit-information`,
    buildFloorplanUri: (buildingId: string, floorplanId: string) =>
        `/portfolio/${buildingId}/floorplan/${floorplanId}`,
};

export const DEVELOPMENT_PORTFOLIO_URL = {
    landing: "/portfolio-development",
};

export const RESIDENTIAL_PORTFOLIO_URL = {
    landing: "/portfolio-residential",
    form: "/portfolio-residential/form",
    buildDetailUri: (id: string) => `/portfolio-detail-residential/${id}`,
};

export const RESIDENTIAL_URL = {
    landing: "/residential",
    detailBase: "/listing",
    buildDetailPageUri: (id: string) => `/listing/${id}`,
};

export const DEVELOPMENT_URL = {
    landing: "/development",
    detailBase: "/development",
    buildDetailPageUri: (id: string, info?: string) =>
        `/development/${id}${info ? `/${info}` : ""}`,
};

export const COMMUNITY_URL = {
    landing: "/community",
    detailBase: "/community",
    buildDetailPageUri: (name: string) => `/community/${name}`,
};

export const CONDO_URL = {
    landing: "/condo",
    detailBase: "/condo",
    buildDetailPageUri: (id: string) => `/condo/${id}`,
};

export const PRICE_PREDICTION_URL = {
    buildDetailPageUri: (id: string) => `/pricePrediction/${id}`,
};

export const ASSIGN_URL = {
    landing: "/assign",
    buildDetailPageUri: (id: string) => `/assign/${id}`,
};

export const AGENT_URL = {
    landing: "/agent",
    buildDetailPageUri: (id: string) => `/agent/${id}`,
};

export const LAWYER_URL = {
    landing: "/lawyer",
};

export const BHPP_URL = {
    landing: "/ai",
    buildFormUri: (address: string) => `/ai/${address}`,
    buildDetailUri: (predictionId: string) => `/ai/${predictionId}/detail`,
};

export const PROPOSED_URL = {
    landing: "/proposed",
    map: "/proposed/map",
};
