import useScrollPosition from "@react-hook/window-scroll";
import { Dropdown, Grid, Layout, Menu, Row, Avatar } from "antd";
import React, { useEffect } from "react";
import intl from "react-intl-universal";
import { shallowEqual, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ButtonLink, Flex, Link, Spacer, Text } from "src/components";
import { requestLogin } from "src/redux/modules/Login";
import { cleanSignup } from "src/redux/modules/Signup/actions";
import { cleanUserInfo } from "src/redux/modules/UserInfo/actions";
import useSelector from "src/redux/store/useSelector";
import { removeToken } from "src/services/client";
import { FullManorleadLogo } from "src/static";
import { theme } from "src/theme/theme";
import {
    authTitle,
    AUTH_TITLE,
    DEFAULT_PROFILE_AVATAR,
    MARKETING_PAGES,
    MARKETING_PAGE_ROUTES,
    mediaQueries,
} from "src/utils";
import styled, { css } from "styled-components";
import { LocaleSelector } from "../Shared/localeSelector";
import { NavbarProps } from "./index";
import NavbarItem from "./NavbarItem";
import useGoogleAnalytics, { Category } from "src/utils/GoogleAnalytics";
import { ListingMenuButtonFull } from "../Shared/ListingMenuButton";

export const MenuIcon = ({ Icon }) => (
    <Icon style={{ position: "relative", top: "4px", marginLeft: "4px", height: "17px" }} />
);

export const HEADER_CONTENT = [
    {
        title: ["navbar.ai", "navbar.chat", "navbar.manorAgent"],
        route: [
            { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.CHAT], search: "" },
            { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.MANORAGENT], search: "" },
        ],
        icon: <></>,
    },
    {
        title: ["navbar.portfolioDropdown", "navbar.portfolio", "navbar.aboutPortfolio"],
        route: [
            { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.PORTFOLIO], search: "" },
            { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.MANORPORTFOLIO], search: "" },
        ],
        icon: <></>,
    },
    {
        title: ["navbar.sell", "navbar.residential", "navbar.condo"],
        route: [
            {
                pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.RESIDENTIAL],
                search: "leaseOrSale=Sale",
            },
            { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.CONDO], search: "leaseOrSale=Sale" },
        ],
        icon: <></>,
    },
    {
        title: ["navbar.leasingDropdown", "navbar.residentialLease", "navbar.condoLease"],
        route: [
            {
                pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.RESIDENTIAL],
                search: "leaseOrSale=Lease&isExclusive=false",
            },
            {
                pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.CONDO],
                search: "leaseOrSale=Lease",
            },
        ],
        icon: <></>,
    },
    {
        title: ["navbar.assignTitle", "navbar.assignMaps"],
        route: [{ pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.ASSIGN], search: "" }],
        icon: <></>,
    },
    {
        title: "navbar.development",
        route: { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.DEVELOPMENT], search: "" },
        icon: <></>,
    },
    {
        title: [
            "navbar.learnMore",
            "navbar.proposedCondo",
            "navbar.agent",
            "navbar.lawyer",
            "navbar.guides",
        ],
        route: [
            { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.PROPOSED], search: "" },
            { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.AGENT], search: "" },
            { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.LAWYER], search: "" },
            { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.COMMUNITY], search: "" },
        ],
        icon: <></>,
    },
];

export const HEADER_CONTENT_LOGGED_IN = (() => {
    return [
        {
            title: ["navbar.ai", "navbar.chat", "navbar.manorAgent"],
            route: [
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.CHAT], search: "" },
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.MANORAGENT], search: "" },
            ],
            icon: <></>,
        },
        {
            title: [
                "navbar.portfolioDropdown",
                "navbar.portfolio",
                "navbar.purchasedPrecon",
                "navbar.aboutPortfolio",
                "savedHome.Hero.title",
            ],
            route: [
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.PORTFOLIO], search: "" },
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.PURCHASED], search: "" },
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.MANORPORTFOLIO], search: "" },
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.SAVED], search: "" },
            ],
            icon: <></>,
        },
        {
            title: ["navbar.sell", "navbar.residential", "navbar.condo"],
            route: [
                {
                    pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.RESIDENTIAL],
                    search: "leaseOrSale=Sale",
                },
                {
                    pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.CONDO],
                    search: "leaseOrSale=Sale",
                },
            ],
            icon: <></>,
        },
        {
            title: ["navbar.leasingDropdown", "navbar.residentialLease", "navbar.condoLease"],
            route: [
                {
                    pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.RESIDENTIAL],
                    search: "leaseOrSale=Lease&isExclusive=false",
                },
                {
                    pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.CONDO],
                    search: "leaseOrSale=Lease",
                },
            ],
            icon: <></>,
        },
        {
            title: ["navbar.assignTitle", "navbar.assignMaps"],
            route: [{ pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.ASSIGN], search: "" }],
            icon: <></>,
        },
        {
            title: "navbar.development",
            route: { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.DEVELOPMENT], search: "" },
            icon: <></>,
        },
        {
            title: [
                "navbar.learnMore",
                "navbar.proposedCondo",
                "navbar.agent",
                "navbar.lawyer",
                "navbar.guides",
            ],
            route: [
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.PROPOSED], search: "" },
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.AGENT], search: "" },
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.LAWYER], search: "" },
                { pathname: MARKETING_PAGE_ROUTES[MARKETING_PAGES.COMMUNITY], search: "" },
            ],
            icon: <></>,
        },
    ];
})();

export type MenuProps = NavbarProps & {
    logged: boolean;
    dispatchAuth: (title: string, popup: boolean) => void;
};
const { useBreakpoint } = Grid;
const { Content } = Layout;

const DesktopMenu: React.FC<MenuProps> = ({
    children,
    $isImageAsBackground = false,
    logged,
    dispatchAuth,
}) => {
    const { pathname } = useLocation();
    const scrolly = useScrollPosition(60);
    const bp = useBreakpoint();
    const dispatch = useDispatch();
    const [avatar, setAvatar] = React.useState(DEFAULT_PROFILE_AVATAR);
    const loginResponse = useSelector((state) => state.login?.data ?? [], shallowEqual);
    const userInfoResponse = useSelector((state) => state.userInfo?.data ?? false, shallowEqual);
    const { sendEvent } = useGoogleAnalytics();

    const handleLogout = async () => {
        sendEvent(
            Category.BUTTON,
            `User clicked on log out button: ${loginResponse.userProfile?.profileId}`,
            "DesktopMenu",
        );

        await dispatch(
            requestLogin({
                endpoint: "signout",
                body: "body",
                method: "POST",
            }),
        );

        localStorage.removeItem("refresh_token");
        removeToken();
        setAvatar(DEFAULT_PROFILE_AVATAR);
        dispatch(cleanUserInfo());
        dispatch(cleanSignup());
        dispatchAuth(authTitle(AUTH_TITLE.LOGIN), false);
    };

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <Link href="/profile">{intl.get("navbar.accountSetting")}</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3" onClick={handleLogout}>
                {intl.get("navbar.logout")}
            </Menu.Item>
        </Menu>
    );

    const headerContent = logged ? HEADER_CONTENT_LOGGED_IN : HEADER_CONTENT;

    useEffect(() => {
        if (loginResponse.cognitoJWT) {
            if (userInfoResponse.userProfile) {
                setAvatar((avatar) =>
                    userInfoResponse?.userProfile?.profilePictureUrl
                        ? userInfoResponse?.userProfile?.profilePictureUrl + "?" + Date().toString()
                        : avatar,
                );
            } else {
                setAvatar((avatar) =>
                    userInfoResponse?.profilePictureUrl
                        ? userInfoResponse?.profilePictureUrl + "?" + Date().toString()
                        : avatar,
                );
            }
        }
    }, [userInfoResponse, loginResponse]);

    return (
        <>
            <ContentWrapper
                justify="space-between"
                align="middle"
                $isImageAsBackground={$isImageAsBackground}
                scrolly={scrolly}
                style={{
                    paddingLeft: !bp.lg ? 30 : 30,
                    paddingRight: !bp.lg ? 30 : 20,
                    background: scrolly <= 60 && $isImageAsBackground ? "transparent" : "white",
                    position: $isImageAsBackground ? "fixed" : "relative",
                }}
            >
                <Flex align="center">
                    <StyledLink href="/">
                        <img src={FullManorleadLogo} alt="manorlead-logo" />
                    </StyledLink>

                    <ResponsiveSpacer initial={28} />

                    {headerContent.map(({ title, route, icon }) => {
                        return (
                            <NavbarItem
                                route={route}
                                text={title}
                                scrolly={scrolly}
                                key={typeof title === "string" ? title : title[0]}
                                icon={icon}
                            />
                        );
                    })}
                    {window.innerWidth >= 1585 ? (
                        <React.Fragment>
                            <PhoneNumber>{"1 (647) 613-8568"}</PhoneNumber>
                        </React.Fragment>
                    ) : null}
                </Flex>

                <Flex>
                    {logged ? (
                        pathname === "/profile" ? (
                            <div
                                onClick={() => {
                                    handleLogout();
                                }}
                            >
                                <ResponsiveButtonLink text="Log Out" />
                            </div>
                        ) : (
                            <TopRightGroup>
                                <Dropdown
                                    overlay={menu}
                                    trigger={["click"]}
                                    getPopupContainer={(trigger) =>
                                        trigger.parentElement ? trigger.parentElement : trigger
                                    }
                                >
                                    <div
                                        className="ant-dropdown-link"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <AvatarBox>
                                            <Avatar src={avatar} size="large" />{" "}
                                        </AvatarBox>
                                    </div>
                                </Dropdown>
                                <LocaleSelector
                                    ismobile={false}
                                    isSelectorColorBlack={
                                        scrolly >= 60 || window.location.pathname !== "/community"
                                    }
                                />
                            </TopRightGroup>
                        )
                    ) : (
                        <Flex style={{ alignItems: "center" }}>
                            <ResponsiveSpacer initial={8} medium={8} />
                            <StyledAuthButtons
                                onClick={() => {
                                    sendEvent(
                                        Category.BUTTON,
                                        "clicks on log in on nav bar by user",
                                        "DesktopMenu",
                                    );
                                    dispatchAuth(authTitle(AUTH_TITLE.LOGIN), true);
                                }}
                                $fontSize={window.innerWidth >= 1300 ? "16" : "14"}
                                $boxWidth={window.innerWidth >= 1300 ? "86" : "78"}
                            >
                                {intl.get("navbar.login")}
                            </StyledAuthButtons>
                            <RegisterButton
                                onClick={() => {
                                    sendEvent(
                                        Category.BUTTON,
                                        "clicks on sign up on nav bar by user",
                                        "DesktopMenu",
                                    );
                                    dispatchAuth(authTitle(AUTH_TITLE.SIGNUP), true);
                                }}
                                $fontSize={window.innerWidth >= 1400 ? "16" : "14"}
                                $boxWidth={window.innerWidth >= 1400 ? "90" : "82"}
                            >
                                {intl.get("navbar.register")}
                            </RegisterButton>
                            <LocaleSelector
                                ismobile={false}
                                isSelectorColorBlack={
                                    scrolly >= 60 || window.location.pathname !== "/community"
                                }
                            />
                        </Flex>
                    )}
                </Flex>
            </ContentWrapper>
            <Content style={{ position: "relative" }}>{children}</Content>
        </>
    );
};

export default DesktopMenu;

const TopRightGroup = styled.div`
    align-items: center;
    display: flex;
`;

const ContentWrapper = styled(Row)<{
    $isImageAsBackground?: boolean;
    scrolly: any;
}>`
    width: 100%;
    z-index: 10;
    height: 64px;
    min-height: 64px;
    box-shadow: ${({ scrolly, $isImageAsBackground }) =>
        $isImageAsBackground && scrolly <= 60 ? "none" : "rgba(0, 0, 0, 0.08) 0px 1px 12px"};
`;

const AvatarBox = styled.div`
    cursor: pointer;
`;

const linkTextHighlightedStyles = css`
    font-weight: 700;
`;

export const LinkText = styled.p<{
    $isRouteSelected?: boolean;
    scrolly?: any;
}>`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    ${({ theme }) => theme.styleGroup.body}
    color: ${({ scrolly }) =>
        scrolly <= 60 && window.location.pathname === "/community"
            ? theme.color.white
            : theme.color.black};

    ${({ $isRouteSelected }) => $isRouteSelected && linkTextHighlightedStyles}

    ${mediaQueries.medium} {
        font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    }
`;

const PhoneNumber = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    ${({ theme }) => theme.styleGroup.body}
    color: ${({ theme }) => theme.color.goldAccent};
    font-weight: 700;
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
`;

const ResponsiveSpacer = styled(Spacer)<{
    initial: number;
    large?: number;
    medium?: number;
}>`
    width: ${({ initial }) => initial}px;

    ${({ large }) =>
        large &&
        `${mediaQueries.large} {
        width: ${large}px;
    }`}

    ${({ medium }) =>
        medium &&
        `${mediaQueries.medium} {
        width: ${medium}px;
    }`}
`;

const ResponsiveButtonLink = styled(ButtonLink)`
    ${mediaQueries.medium} {
        width: 112px;
    }
    p {
        font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    }
`;

const StyledAuthButtons = styled(ListingMenuButtonFull)<{
    $fontSize: string;
    $boxWidth: string;
}>`
    width: ${({ $boxWidth }) => `${$boxWidth}px`};
    height: 35px;
    font-size: ${({ $fontSize }) => `${$fontSize}px`};
    font-weight: 400;
`;

const RegisterButton = styled(StyledAuthButtons)`
    color: ${({ theme }) => theme.color.black};
    background-color: ${({ theme }) => theme.color.manorleadGold};

    :hover {
        color: ${({ theme }) => theme.color.black};
        background-color: ${({ theme }) => theme.color.manorleadGold};
    }
`;
